/* .chakra-portal .menu-modal.chakra-modal__overlay {
  top: 155px;
} */

.chakra-switch span.chakra-switch__track[data-checked] {
  background-color: #d6006d;
}

.search-mobile-menu {
  position: relative;
}

.chakra-portal .menu-modal-content.chakra-modal__content {
  /* margin-top: 155px; */
  margin-top: initial;
  border-radius: 0px;
}

.toSticky svg path {
  fill: #d6006d;
}

/* BURGER MENU OVERRIDE STYLES */
.bm-menu-wrap nav {
  padding: 15px;
}

.bm-menu-wrap nav a.bm-item {
  display: flex !important;
  justify-content: space-between;
}

/* BURGER STYLES */
.custom-burger-styles {
  margin-top: 12px !important;
}

.custom-burger-styles span {
  height: 2px;
}
.custom-burger-styles span::before,
.custom-burger-styles span::after {
  height: 2px;
}

.custom-burger-styles:focus {
  outline: none;
}

/* LEVEL ONE STYLES */
.level-generic {
  padding: 15px;
}

.level-generic .menu-item {
  display: flex;
  justify-content: space-between;
}

.level-generic .menu-item.back > div {
  margin-right: 8px !important;
}

.level-generic .menu-item.back {
  display: flex;
  justify-content: flex-end;
}
.level-generic .menu-item.back img {
  transform: rotate(180deg);
}

/* MOBILE MENU SEARCH FIELD OVERRIDE STYLES */
.react-search-field {
  width: 100%;
  border: 1px solid #d6006d !important;
  border-radius: 5px;
  margin-bottom: 20px;
}
.react-search-field input {
  margin-left: 33px;
  margin-right: 4px;
}

.react-search-field button {
  position: absolute;
  border-left: none !important;
  margin-left: 2px;
}

/* components/common/ProductPhotosMobile */
.ProductPhotosMobile__item {
  text-align: center;
}

.square-ratio {
  position: relative;
  /* margin-right: 5px; */
}
.square-ratio::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.line {
  position: relative;
}

.line::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 5000px;
  background-color: #d6006d;
  left: -600px;
  bottom: 0;
}

.checkBoxAddress div {
  font-size: 14px;
}
.checkBoxAddress .chakra-checkbox__control {
  border-color: #d6006d;
}

.radioAddress {
  border-color: #d6006d !important;
}

.blog-carousel .react-multi-carousel-list {
  height: 100% !important;
  width: 280px !important;
}

@media screen and (min-width: 520px) {
  .blog-carousel .react-multi-carousel-list {
    width: 390px !important;
  }
}

/* STICKY MENU ON TOP */
nav#jsAmmMenu {
  position: relative;
  transition: all 500ms ease;
}
.toSticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1001;
}

.toSticky .chakra-text {
  margin-left: 25px;
  margin-right: 25px;
}

.toSticky .ammMiniCart__trigger svg path {
  fill: #d6006d;
}

.toSticky .ammMiniCart__indicator {
  bottom: -3px;
}

#jsAmmMenu .chakra-text {
  transition: all 500ms ease;
}

.customMargin {
  margin-bottom: 65px;
}

.noticeClassMargin {
  margin-top: 65px;
}

/* MAP CUSTOM STYLES */

#mapCustomID {
  overflow: visible !important;
}
#mapCustomID .gm-style {
  overflow: hidden;
}

#mapCustomID .gm-style-iw button {
  display: none !important;
}

#mapCustomID .gm-style-iw-t {
  bottom: 35px !important;
}

#mapCustomID div:nth-child(2) {
  position: absolute;
  width: 100%;
  top: -55px;
}

#mapCustomID div:nth-child(2) input {
  border: 1px solid #909090;
  border-radius: 0;
}
#mapCustomID div:nth-child(2) input::placeholder {
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  color: #222222;
}

#mapCustomID div:nth-child(2) input::before {
  content: 'This Is Test';
  position: absolute;
  display: block;
}

.ammStoresBtn {
  box-shadow: none !important;
}

.ammStoresBtn:hover svg path {
  fill: #e81f78;
}

.css-1emig0n[aria-selected='true'] {
  color: black !important;
  font-weight: bold;
  box-shadow: none !important;
  border-color: #d6006d !important;
  border-bottom: solid 4px red;
}

.chakra-link,
.chakra-button {
  box-shadow: none !important;
}

.modalInfoMap div {
  position: relative !important;
  top: unset !important;
}

*:focus {
  box-shadow: none !important;
}

*[data-focus] {
  box-shadow: none !important;
}

/* CART BOX POSITION */
.cart-box-parent .chakra-popover__popper {
  right: calc(50% - 170px);
  top: 35px;
}

.cart-box-parent-mobile .chakra-popover__popper {
  top: 43px;
  right: -20px;
}
@media screen and (min-width: 766px) {
  .cart-box-parent-mobile .chakra-popover__popper {
    right: -40px;
  }
}

.cart-box-parent-mobile .chakra-popover__popper section {
  width: 100vw;
}

.react-date-picker {
  height: 40px;
}

.css-1o5k2cy[aria-selected='true'] {
  color: var(--chakra-colors-brand-900) !important;
  border-color: currentColor !important;
}

/* REACT PLAYER VIDEO */
.video-container video {
  object-fit: cover;
}

/* MENU ITEM */
.menu_container {
  flex-flow: wrap;
}

#footerMenu {
  transform: translate(-50%);
}

/* LANGUAGE SUB MENU */
.css-r6z5ec {
  z-index: 1002 !important;
}

.tabSection > p {
  margin-bottom: 40px;
}

.error-page > div:not(.message) {
  position: absolute;
  left: 0;
  top: 0;
}

.error-page div video {
  object-fit: cover;
}

.choose_btn > div {
  left: 0;
}

.select-language option,
.select-country option {
  color: black;
}

.single-product-carousel .react-multi-carousel-track {
  margin-bottom: 50px;
}
.single-product-carousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
.single-product-carousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  bottom: 0;
  z-index: 9;
}
@media screen and (min-width: 576px) {
  .single-product-carousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
  .single-product-carousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    bottom: unset;
  }
}

.react-multiple-carousel__arrow {
  z-index: 9 !important;
}

/* SPINNER STYLES */
.spinner {
  color: #d6006d;
  font-size: 20px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
      -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
      -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
      -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
      -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}

.form-input-field input,
.form-input-field textarea {
  border: 1px solid #909090;
  height: 40px;
  border-radius: 0;
  padding-left: 8px;
}

.video-instagram video {
  object-fit: cover;
}

.birthday .react-date-picker__wrapper {
  width: 150px;
}

.birthday .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month,
.birthday .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day {
  width: 25px !important;
  text-align: center;
}

.birthday .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
  width: 35px !important;
  text-align: center;
}

.content table {
  width: 100%;
  margin: 30px 0;
  border-collapse: separate;
  border-spacing: 15px;
}

.content table tr {
  position: relative;
}

.content table tr::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  position: absolute;
  bottom: -9px;
}

.newsletter-validation .chakra-form__error-message {
  color: white;
}

/* close button */
.close-btn {
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close-btn:hover {
  opacity: 1;
}
.close-btn:before,
.close-btn:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close-btn:before {
  transform: rotate(45deg);
}
.close-btn:after {
  transform: rotate(-45deg);
}

.chakra-form__required-indicator {
  color: #d41c1c !important;
}

.video > div {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1023px) {
  .above-1024 {
    display: none;
  }
}
@media (min-width: 1024px) {
  .below-1024 {
    display: none;
  }
}

@media (max-width: 767px) {
  .above-768 {
    display: none;
  }
}
@media (min-width: 768px) {
  .below-768 {
    display: none;
  }
}
