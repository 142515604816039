html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.pswp__item {
  background-color: white;
}

.pswp__img--placeholder--blank {
  background: #ffff !important;
}

.chakra-alert button {
  color: #d6006d;
}

/* snow effect homepage */
.snow canvas {
  z-index: 1;
}

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

/* SWIPER SLIDER  */
.hero-swiper .swiper-button-prev,
.hero-swiper .swiper-button-next {
  border-radius: 50%;
  background-color: #ffffff;
  width: 45px;
  height: 45px;
  transition: 0.4s;
}

.hero-swiper .swiper-button-prev:hover,
.hero-swiper .swiper-button-next:hover {
  background-color: #d6006d !important;
}

.hero-swiper .swiper-button-prev::after,
.hero-swiper .swiper-button-next::after {
  font-size: 22px;
  color: #d6006d;
  position: absolute;
  top: 12px;
}
.hero-swiper .swiper-button-prev:hover::after,
.hero-swiper .swiper-button-next:hover::after {
  color: #ffffff;
}

.hero-swiper .swiper-button-prev::after {
  left: 16px;
}
.hero-swiper .swiper-button-next::after {
  left: 19px;
}

.instagram-grid:not(:last-child) {
  margin-right: 10px !important;
}

.chakra-popover__popper {
  z-index: 5000 !important;
}

/* iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

#ammBoxNowMap iframe {
  top: 75px;
}

.black-friday {
  -webkit-background-size: cover !important;
  background-size: cover !important;
}

.video-product video {
  object-fit: cover;
}

.text-align-center {
  text-align: center;
}

#sbl_recommendations {
  position: relative;
}

.gm-style-iw {
  padding: 20px;
  width: 100%;
  height: 300px;
}

.modalInfoMap {
  padding-top: 78px !important;
}

.basic-images:last-child {
  padding-left: 20px;
}
